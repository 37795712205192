/**
 * This mixin implements CRUD using API calls to Laravel
 */

export default {
  data () {
    return {
      notifications: [],
      totalNotifications: 0,
    }
  },

  methods: {
    translate (n) {
      n.color = 'info'
      n.icon = 'mdi-bell'
      n.date = n.created_at.substr(8, 2) + '.' + n.created_at.substr(5, 2) + ' ' + n.created_at.substr(11, 5)
      if (n.text) {
        n.text = this.$t(n.text)
      } else {
       switch (n.type) {
        case 'App\\Notifications\\UserCreated':
          n.text = 'New user: ' + n.data.name
          n.icon = 'mdi-account-plus'
          n.color = 'warning'
          break
        }
      }
      return n
    },
  },
}
