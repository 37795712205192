<template>
  <v-container id="alerts" fluid tag="section">
    <v-card>
      <v-card-text>
        <base-subheading :subheading="$t('Latest unread notifications')" />

        <base-material-alert
          v-for="item in notifications"
          :key="'not' + item.id"
          :color="item.color"
          :icon="item.icon"
          >{{ item.date }} - {{ item.text }}</base-material-alert
        >
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import notificationMixin from "mixins/notifications";

export default {
  name: "Notifications",
  mixins: [notificationMixin],

  data: () => ({}),

  created() {
    this.get("/notifications/").then((data) => {
      this.notifications = data.map(this.translate);
    });
  },

  methods: {},
};
</script>
